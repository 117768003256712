import { Dispatch } from '@redux/store';
import { createModel } from '@rematch/core';
import { TravelingService } from '@services';
import {
  PaginationType,
  RootModel,
  RouteRefPointResponseType,
  RouteRefPointType,
  TravelingDtoType,
} from '@types';
import { nonNull } from '@utils';
import { parseDirection } from '@utils/directionUtils';
import baseEntity from './base.entity';

interface TravelingEntitiesState {
  ids: { [id: number]: TravelingDtoType };
}

const initialState: TravelingEntitiesState = {
  ids: {},
};

export const travelingEntities = createModel<RootModel>()({
  state: initialState,
  reducers: {
    ...baseEntity,
    setRouteRefPoint(state, payload: { id: number; routeRefPoint: RouteRefPointResponseType }) {
      if (!payload) {
        return;
      }

      const { routeRefPoint, id } = payload;
      Object.keys(routeRefPoint).forEach((code) => {
        routeRefPoint[code]?.forEach((refPt) => {
          if (!nonNull(refPt.dist)) {
            refPt.dist = 0;
          }
        });
      });

      state.ids[id].routeRefPoint = routeRefPoint;
    },
    addRouteRefPoint(
      state,
      payload: {
        id: number;
        locationCode: string;
        refPoints: RouteRefPointType[];
      }
    ) {
      if (payload) {
        const { id, locationCode, refPoints = [] } = payload;

        const traveling = state.ids[id];
        if (traveling.routeRefPoint) {
          if (!traveling.routeRefPoint[locationCode]) {
            traveling.routeRefPoint[locationCode] = refPoints;
          } else {
            refPoints.forEach((refPoint) => {
              const existed = !!traveling.routeRefPoint![locationCode].find((rp) => {
                return (
                  rp.point.lng === refPoint.point.lng &&
                  rp.point.lat === refPoint.point.lat &&
                  rp.pointIndex === refPoint.pointIndex
                );
              });
              if (!existed) {
                traveling.routeRefPoint![locationCode].push(refPoint);
                traveling.routeRefPoint![locationCode].sort((a, b) => a.pointIndex - b.pointIndex);
              }
            });
          }
        } else {
          traveling.routeRefPoint = {
            [locationCode]: refPoints,
          };
        }
      }
    },
    removeRouteRefPoint(
      state,
      payload: {
        id: number;
        locationCode: string;
        refPoint: RouteRefPointType;
      }
    ) {
      if (payload) {
        const { id, locationCode, refPoint } = payload;
        const traveling = state.ids[id];
        if (traveling.routeRefPoint && traveling.routeRefPoint[locationCode]) {
          const index = traveling.routeRefPoint[locationCode].findIndex((rp) => {
            return (
              rp.point.lng === refPoint.point.lng &&
              rp.point.lat === refPoint.point.lat &&
              rp.pointIndex === refPoint.pointIndex
            );
          });
          if (index >= 0) {
            traveling.routeRefPoint[locationCode].splice(index, 1);
          }
        }
      }
    },
    updateRouteRefPoint(
      state,
      payload: {
        id: number;
        locationCode: string;
        refPoint: RouteRefPointType;
      }
    ) {
      if (payload) {
        const { id, locationCode, refPoint } = payload;
        const traveling = state.ids[id];
        if (traveling.routeRefPoint?.[locationCode]) {
          const index = traveling.routeRefPoint[locationCode].findIndex((rp) => {
            return (
              rp.point.lng === refPoint.point.lng &&
              rp.point.lat === refPoint.point.lat &&
              rp.pointIndex === refPoint.pointIndex
            );
          });
          if (index >= 0) {
            traveling.routeRefPoint[locationCode].splice(index, 1, refPoint);
          }
        }
      }
    },
    updateDirection(state, payload: { id: number; direction: any }) {
      if (payload?.id && payload?.direction) {
        const { id, direction } = payload;
        state.ids[id].formattedDirection = direction;
        state.ids[id].direction = JSON.stringify(direction);
      }
    },
  },
  effects: (dispatch: Dispatch) => ({
    async save(payload: TravelingDtoType) {
      if (payload?.id) {
        const { routeRefPoint, referencePoints, direction } = payload;
        let rrp = routeRefPoint;
        if (!rrp && referencePoints) {
          try {
            const pts: RouteRefPointResponseType = JSON.parse(referencePoints);
            Object.keys(pts).forEach((locationCode) => {
              pts[locationCode]?.forEach((refPt) => {
                if (!nonNull(refPt.dist)) {
                  refPt.dist = 0;
                }
              });
            });

            rrp = pts;
          } catch (e) {
            console.error(e);
          }
        }

        const newEntity: TravelingDtoType = {
          ...payload,
          simplified: false,
          locations: undefined,
          routeRefPoint: rrp,
          formattedDirection: parseDirection(direction),
        };
        this.saves([newEntity]);
      }
      if (payload.locations?.length) {
        dispatch.locationEntities.saveSimplified(payload.locations);
      }
      return payload;
    },
    async load(payload): Promise<{ content: TravelingDtoType[] } & PaginationType> {
      const res = await TravelingService.filterTravelings(payload);
      const { content } = res.data;
      if (content && content.length) {
        this.saveSimplified(content);
      }
      return res.data;
    },
    async findById(id, state): Promise<TravelingDtoType> {
      let item = state.travelingEntities.ids[id];
      if (item && !item.simplified) {
        return item;
      }
      item = await dispatch.travelingEntities.fetchById(id);
      return item;
    },
    async fetchById(id: number): Promise<TravelingDtoType> {
      const { data } = await TravelingService.getTraveling(id);
      await this.save(data);
      return data;
    },
    async updateLocations(payload: { id: number; locationIds: number[] }, state) {
      const { id, locationIds = [] } = payload;
      const locationEntities = state.locationEntities.ids;
      const req = {
        id,
        locations: locationIds.map((i) => {
          const loc = locationEntities[i];
          return {
            ...loc,
            id: loc.new ? undefined : loc.id,
          };
        }),
      };
      const { data } = await TravelingService.updateLocations(id, req);
      await this.save(data);
      return data;
    },
    async saveDirection(payload: { id: number; locationIds: number[] }, state) {
      const { id, locationIds = [] } = payload;
      const traveling = state.travelingEntities.ids[id];
      const locationEntities = state.locationEntities.ids;
      const req = {
        id,
        direction: JSON.stringify(traveling.formattedDirection),
        locations: locationIds.map((i) => locationEntities[i]).filter((l) => !!l.lat && !!l.lng),
      };
      const { data } = await TravelingService.updateDirection(id, req);
      await this.save(data);
      return data;
    },
    async updateReferencePoints(id, state) {
      const traveling = state.travelingEntities.ids[id];
      const req = {
        id,
        referencePoints: JSON.stringify(traveling.routeRefPoint),
      };
      const { data } = await TravelingService.updateRefPoints(id, req);
      await this.save(data);
      return data;
    },
    async updateBgSound(payload: { id: number; data: any }) {
      const { data } = await TravelingService.updateBgSound(payload.id, payload.data);
      await this.save(data);
      return data;
    },
    async createFromTrip(tripId: number) {
      const { data } = await TravelingService.createFromTrip(tripId);
      await this.saveSimplified([data]);
      return data;
    },
    async submit(payload: TravelingDtoType) {
      const { data } = await TravelingService.update(payload.id, payload);
      await this.save(data);
      return data;
    },
  }),
});
