// OTRIP travel blog
export const PERMISSION_CREATE_BLOG = 'OTRIP_CreateTravelBlog';
export const PERMISSION_UPDATE_BLOG = 'OTRIP_UpdateTravelBlog';
export const PERMISSION_DELETE_BLOG = 'OTRIP_DeleteTravelBlog';
export const PERMISSION_PUBLISH_BLOG = 'OTRIP_PublishTravelBlog';
export const PERMISSION_VIEW_BLOGS = 'OTRIP_ViewTravelBlogs';

// OTRIP bus tour
export const PERMISSION_CREATE_BUS_TOUR = 'OTRIP_CreateBusTour';
export const PERMISSION_UPDATE_BUS_TOUR = 'OTRIP_UpdateBusTour';
export const PERMISSION_DELETE_BUS_TOUR = 'OTRIP_DeleteBusTour';
export const PERMISSION_PUBLISH_BUS_TOUR = 'OTRIP_PublishBusTour';
export const PERMISSION_VIEW_BUS_TOURS = 'OTRIP_ViewBusTours';

// OTRIP traveling
export const PERMISSION_CREATE_TRAVELING = 'OTRIP_CreateTraveling';
export const PERMISSION_UPDATE_TRAVELING = 'OTRIP_UpdateTraveling';
export const PERMISSION_DELETE_TRAVELING = 'OTRIP_DeleteTraveling';
export const PERMISSION_VIEW_TRAVELINGS = 'OTRIP_ViewTravelings';
export const PERMISSION_SHARE_TRAVELINGS = 'OTRIP_ShareTravelings';
